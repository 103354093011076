import axios from 'axios';
import { STATES_API } from '../../layouts/API';

const AllStatesMethod = async (token) => {
	let headers = {
		Accept: 'application/json',
		token: 'bL4r$a$c$e6JA8cU',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${STATES_API}`,
		headers: headers,
	});
	return response.data;
};

const StateService = {
	AllStatesMethod,
};

export default StateService;
