import React from 'react';

import image from '../layouts/images/9844-loading-40-paperplane.gif';
import styled from 'styled-components';

function LoadingAnimation() {
	return (
		<LoadingContainer>
      {/* <Lottie loop={true} animationData={elevateLoader} /> */}
			<img src={image} alt="loading animation" />
		</LoadingContainer>
	);
}
const LoadingContainer = styled.div`
    img {
        width: 50%;
        display: flex;
        margin: auto;
    }
`

export default LoadingAnimation;
