import styled from 'styled-components';

export const ShadowContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background: #88888857;
	top: 0;
	left: 0;
	z-index: 4;
`;

export const DefaultPopupStyle = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 5;
	background: #fff;
	width: 40%;
	transform: translate(-50%, -50%);
	border-radius: 13px;
	padding: 10px;
`;
