import styled from 'styled-components';
import {
	DefaultPopupStyle,
	ShadowContainer,
} from '../../../layouts/Styled/PopUp';
import { Button, Grid, Modal, Typography } from '@mui/material';
import { Delete, HighlightOff } from '@material-ui/icons';
import { Box } from '@mui/system';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

function DeletePopUp({ open, hendleClose, title, handleDeleteSubmit }) {
	if (open == true) {
		return (
			<ShadowContainer>
				<DefaultPopupStyle>
					<DeleteContainer>
						<IconContainer container spacing={2}>
							<HighlightOff />
						</IconContainer>

						<Typography
							className="text-sub"
							sx={{ fontWeight: 'bold' }}
							variant="h5"
							align="center"
						>
							Are You Sure To Delete ?
						</Typography>
						<Typography sx={{ fontWeight: 'bold' }} variant="h6" align="center">
							{title}
						</Typography>
						<Grid container mt={3} spacing={2}>
							<Grid item md={6}>
								<Button
									onClick={handleDeleteSubmit}
									sx={{ width: '50%', margin: 'auto', display: 'flex' }}
									className="bg-main"
									variant="contained"
									color="error"
								>
									Delete
								</Button>
							</Grid>

							<Grid item md={6}>
								<Button
									onClick={hendleClose}
									sx={{ width: '50%', margin: 'auto', display: 'flex' }}
									variant="outlined"
								>
									Close
								</Button>
							</Grid>
						</Grid>
					</DeleteContainer>
				</DefaultPopupStyle>
			</ShadowContainer>
		);
	}
}

const IconContainer = styled.div`
	svg {
		font-size: 12em;
		display: flex;
		margin: auto;
		color: var(--main-color);
	}
`;

const DeleteContainer = styled.div``;

export default DeletePopUp;
