export const GetErrorsObj = (action) => {
	return action?.payload?.response?.data?.errors;
};

export const GetResponseCode = (action) => {
	return action?.payload?.response?.status;
};

export const GetErrorMessage = (action) => {
	return action?.payload?.response?.data?.message;
};
