import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Profile = () => {
	return (
		<ProfileContainer>
            <Outlet />
		</ProfileContainer>
	);
};

const ProfileContainer = styled.div``;

export default Profile;
